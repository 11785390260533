<!-- @format -->

<script>
import { BTabs, BTab, BTable, BForm, BFormGroup } from 'bootstrap-vue'
import axiosIns from '@/libs/axios'
import { getCurrentInstance, nextTick, onMounted, ref } from '@vue/composition-api'

const zhifujiluClumn = [
  {
    label: '会员名称',
    key: 'user',
  },
  {
    label: '保险公司',
    key: 'bx_company',
  },
  {
    label: '产品',
    key: 'product.title',
  },
  {
    label: '保单号',
    key: 'number.number',
  },
  {
    label: '受保人',
    key: 'protect_user',
  },
  {
    label: '付款日期',
    key: 'pay_time',
  },
  {
    label: '佣金类型',
    key: 'count_cate',
  },
  {
    label: '支付TP',
    key: 'pay_tp',
  },
  {
    label: '派发率(%)',
    key: 'rate',
  },
  {
    label: '个人总佣金',
    key: 'personal_pay_commission',
  },
  {
    label: '个人未付TP',
    key: 'personal_unpay_tp',
  },
  {
    label: '个人未付佣金',
    key: 'personal_unpay_commission',
  },
]

const gerenyj = [
  {
    label: '姓名',
    key: 'real_name',
  },
  {
    label: 'ID',
    key: 'id',
  },
  {
    label: '等级',
    key: 'level',
  },
  {
    label: '总TP',
    key: 'all_tp',
  },
  {
    label: '派发率(%)',
    key: 'rate',
    formatter: value => (value ? `${Number(value) * 100}%` : value),
  },
  {
    label: '总个人等级佣金',
    key: 'income',
  },
  {
    label: '已付TP',
    key: 'pay_tp',
  },
  {
    label: '未付TP',
    key: 'not_pay_tp',
  },
  {
    label: '已付佣金',
    key: 'pay_income',
  },
  {
    label: '未付佣金',
    key: 'not_pay_income',
  },
]
const gerenyj2 = [
  {
    key: 'real_name',
    label: '姓名',
  },
  {
    key: 'id',
    label: '序号',
  },
  {
    key: 'level',
    label: '等级',
  },
  {
    key: 'pay_time',
    label: '付款日期',
  },
  {
    key: 'pay_tp',
    label: '付款TP',
  },
  {
    key: 'cate_name',
    label: '付款类型',
  },
  {
    key: 'share_tp',
    label: '分担TP',
  },
  {
    key: 'income',
    label: '已付总佣金',
  },
]

const cengjiYongj = [
  {
    label: '姓名',
    key: 'real_name',
  },
  {
    label: 'ID',
    key: 'id',
  },
  {
    label: '等级',
    key: 'level',
  },
  {
    label: '总TP',
    key: 'all_tp',
  },
  {
    label: '总层级差佣金',
    key: 'user_level_gap',
  },
  {
    label: '已付TP',
    key: 'pay_tp',
  },
  {
    label: '未付TP',
    key: 'not_pay_tp',
  },
  {
    label: '已付佣金',
    key: 'pay_income',
  },
  {
    label: '未付佣金',
    key: 'not_pay_income',
  },
]

const tuanDuiYongj = [
  {
    label: '姓名',
    key: 'real_name',
  },
  {
    label: 'ID',
    key: 'id',
  },
  {
    label: '等级',
    key: 'level',
  },
  {
    label: '总TP',
    key: 'all_tp',
  },
  {
    label: '总团队佣金',
    key: 'total_team_commission',
  },
  {
    label: '已付TP',
    key: 'pay_tp',
  },
  {
    label: '未付TP',
    key: 'not_pay_tp',
  },
  {
    label: '已付团队佣金',
    key: 'pay_team_commission',
  },
  {
    label: '未付团队佣金',
    key: 'unpay_team_commission',
  },
]

export default {
  components: {
    BTabs,
    BTab,
    BTable,
    BForm,
    BFormGroup,
  },
  data() {
    return {
      zhifujiluClumn,
      gerenyj,
      gerenyj2,
      cengjiYongj,
      tuanDuiYongj,
      active: 0,
    }
  },

  setup() {
    const { proxy } = getCurrentInstance()

    // y佣金详情--------------------------------------
    const getZhifujiluDataArray = ref([])
    const detailDataForm = ref([])
    const getZhifujiluData = () => {
      axiosIns.get(`/user-income/detail?order_id=${proxy.$route.query.id}`).then(res => {
        getZhifujiluDataArray.value = res.data.list
        detailDataForm.value = res.data.pay
      })
    }

    // 个人佣金详情--------------------------------------
    const gerenyjDataArray = ref([])
    const genrenyjDataForm = ref([])
    const gerenyjData = () => {
      axiosIns.get(`/user-income/personal?order_id=${proxy.$route.query.id}`).then(res => {
        gerenyjDataArray.value = res.data.list
        genrenyjDataForm.value = res.data.pay
      })
    }

    // 层级佣金详情--------------------------------------
    const cengjiYongjDataArray = ref([])
    const cengjiYongjDataForm = ref([])
    const cengjiYongjData = () => {
      axiosIns.get(`/user-income/level?order_id=${proxy.$route.query.id}`).then(res => {
        cengjiYongjDataArray.value = res.data.list
        console.log(cengjiYongjDataArray)
        cengjiYongjDataForm.value = res.data.pay
      })
    }

    // 团队佣金详情--------------------------------------
    const tuanDuiYongjDataArray = ref([])
    const tuanDuiYongjDataForm = ref([])
    const tuanDuiYongjData = () => {
      axiosIns.get(`/user-income/team?order_id=${proxy.$route.query.id}`).then(res => {
        tuanDuiYongjDataArray.value = res.data.list
        tuanDuiYongjDataForm.value = res.data.pay
      })
    }
    onMounted(() => {
      nextTick(() => {
        getZhifujiluData()

        gerenyjData()

        cengjiYongjData()

        tuanDuiYongjData()
      })
    })

    return {
      getZhifujiluDataArray,
      gerenyjDataArray,
      detailDataForm,
      genrenyjDataForm,
      cengjiYongjDataArray,
      cengjiYongjDataForm,
      tuanDuiYongjDataArray,
      tuanDuiYongjDataForm,
    }
  },
}
</script>

<template>
  <div>
    <b-tabs card v-model="active">
      <b-tab title="佣金详情">
        <b-table :fields="zhifujiluClumn" bordered :items="getZhifujiluDataArray">
          <template #cell(user)="data">
            <div>{{ data.value.real_name }}</div>
            <div>{{ data.value.id }}</div>
            <div>{{ data.value.level }}</div>
          </template>
        </b-table>
        <b-card title="付款信息">
          <b-table :fields="gerenyj2" bordered :items="detailDataForm" />
        </b-card>
      </b-tab>
      <b-tab title="个人佣金">
        <b-card title="个人佣金信息">
          <b-table :fields="gerenyj" bordered :items="gerenyjDataArray" />
        </b-card>

        <b-card title="付款信息">
          <b-table :fields="gerenyj2" bordered :items="genrenyjDataForm" />
        </b-card>
      </b-tab>
      <b-tab title="层级佣金">
        <b-card title="层级佣金信息">
          <b-table :fields="cengjiYongj" bordered :items="cengjiYongjDataArray" />
        </b-card>

        <b-card title="付款信息">
          <b-table :fields="gerenyj2" bordered :items="cengjiYongjDataForm" />
        </b-card>
      </b-tab>
      <b-tab title="团队佣金">
        <b-card title="团队佣金信息">
          <b-table :fields="tuanDuiYongj" bordered :items="tuanDuiYongjDataArray" />
        </b-card>

        <b-card title="付款信息">
          <b-table :fields="gerenyj2" bordered :items="tuanDuiYongjDataForm" />
        </b-card>
      </b-tab>
    </b-tabs>
  </div>
</template>
